import React from 'react';
import { TTextColors, Text } from '../text';

export type TButtonTypes = 'tag' | 'green' | 'selectedTag' | 'blue';

const COLORS: Record<TButtonTypes, string> = {
	tag: 'bg-[#d5d7dd] !border border-white hover:bg-[#f3f4f6] active:bg-[#cfd3e0] focus:bg-[#cfd3e0] disabled:bg-[#d5d7dd] disabled:active:bg-[#d5d7dd] disabled:focus:bg-[#d5d7dd]',
	selectedTag:
		'bg-[#2e3138] !border border-white hover:bg-[#41454e]  active:bg-[#2a2e37] focus:bg-[#2a2e37] disabled:bg-[#2e3138] disabled:active:bg-[#2e3138] disabled:focus:bg-[#2e3138]',
	green:
		'bg-[#3ead3e] !border border-[#3ead3e] hover:bg-[#17bd17] active:bg-[#009800] focus:bg-[#009800] disabled:bg-[#00b300] disabled:active:bg-[#00b300] disabled:focus:bg-[#00b300]',
	blue: 'bg-[#08c] !border border-[#08c] hover:bg-[#1795d4] active:bg-[#0074ad] focus:bg-[#0074ad] disabled:bg-[#08c] disabled:active:bg-[#08c] disabled:focus:bg-[#08c]',
};

const TEXT_COLORS: Record<TButtonTypes, TTextColors> = {
	tag: 'gray',
	selectedTag: 'white',
	green: 'white',
	blue: 'white',
};

interface IButtonProps {
	buttonType?: TButtonTypes;
	disabled?: boolean;
	bold?: boolean;
	onClick?: (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => void;
	children: React.ReactNode;
	className?: string;
}

const Button = ({
	buttonType = 'tag',
	disabled = false,
	bold = true,
	onClick,
	children,
	className,
	...props
}: IButtonProps): JSX.Element => {
	return (
		<button
			{...props}
			disabled={disabled}
			className={`
        focus:outline-none inline-flex select-none py-1.5 px-1 items-center text-sm whitespace-nowrap rounded-lg w-full
        ${bold && 'font-semibold'}
        ${disabled && 'cursor-not-allowed opacity-55'}
        ${COLORS[buttonType]}
        ${className}
        
      `}
			onClick={onClick}
		>
			<div className='flex h-full w-full items-center justify-center'>
				<Text color={TEXT_COLORS[buttonType]} className='text-sm' size='small'>
					{children}
				</Text>
			</div>
		</button>
	);
};

export { Button };
