const BASE_URL = 'https://analytics-beta.maxhealthcaredigital.com';

export interface IReportData {
	e4_count: number;
	e3_count: number;
	e2_count: number;
	e1_count: number;
	ne_count: number;
	total_escalations: number;
}

export async function fetchEscalationReport(
	branchId: string
): Promise<IReportData> {
	const url = `${BASE_URL}/get-escalation-report?branch_id=${branchId}`;

	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'ngrok-skip-browser-warning': 'true',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();

		return data;

		// return ESCALATION_REPORT_DATA[branchId];
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}
