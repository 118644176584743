import { TDelayRemark } from '.';

export type TEscalationAction =
	| 'DOCTOR_DELAY'
	| 'DOCTOR_LEAVE'
	| 'MANUAL_PRESCRIPTION'
	| 'DOCTOR_NOT_REACHABLE';

export type TEscalationLevel =
	| 'NO_ESCALATION'
	| 'E1'
	| 'E2'
	| 'E3'
	| 'E4'
	| 'SILENCED';

export interface IEscalationData {
	doctor_id: string;
	doctor_name: string;
	doctor_delay_duration: number | null;
	total_wait_time: number;
	avg_wait_time: number;
	// TODO: add in api
	room: string;
	acknowledged: boolean;
	escalation_level: TEscalationLevel;
	escalations: IEscalatedAppointment[];
}

export interface IEscalatedAppointment {
	appointment_id: string;
	clinic_id: string;
	clinic_name: string;
	doctor_name: string;
	appointment_date: number;
	doctor_comment: TDelayRemark | null;
	start_time: number;
	end_time: number;
	patient_name: string;
	mobile: string;
	uhid: string;
	patient_checkedin_time: number | null;
	next_escalation_time: number;
	escalation_type: TEscalationLevel;
	escalation_action: TEscalationAction | null;
	escalation_start_time: number;
	escalation_end_time: null | number;
	patient_action_taken: null | TPatientAction;
	created_at: number;
	wait_time: number;
}

const BASE_URL = 'https://analytics-beta.maxhealthcaredigital.com';

export async function fetchOPDEscalations(branchId: string): Promise<{
	branch_id: string;
	escalations: IEscalationData[];
	latest_updated_time: string;
}> {
	const url = `${BASE_URL}/get-opd-escalations?branch_id=${branchId}`;

	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'ngrok-skip-browser-warning': 'true',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();

		return data;

		// return {
		// 	branch_id: 'cbb3329e-d89d-4bd2-b64d-e46e20fc018b',
		// 	latest_updated_time: '0',
		// 	escalations: ESCALATION_DATA,
		// };
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}

export type TPatientAction = 'POSITIVE' | 'NEGATIVE';

export interface IEscalationDataToBeUpdated {
	doctor_id: string;
	doctor_delay_duration: number | null;
	doctor_comment: TDelayRemark | null;
	escalation_action: TEscalationAction | null;
	appointments: Array<{
		appointment_id: string;
		patient_action_taken: TPatientAction | null;
	}>;
}

export async function updateEscalationsStatus(
	params: IEscalationDataToBeUpdated
) {
	const url = `${BASE_URL}/update-escalation-status`;

	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				'ngrok-skip-browser-warning': 'true',
			},
			body: JSON.stringify(params),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();
		console.log('Data fetched:', data);

		return data;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}
