import {
	ArrowBack,
	CheckCircleOutlined,
	CheckCircleOutlineOutlined,
	ExpandLess,
	ExpandMore,
	TableViewOutlined as GridIcon,
	LocalHospitalRounded as HospitalIcon,
	Person,
	PrintTwoTone as PrintIcon,
} from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import { EditIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/header';
import { severityColorPalette, titleCase } from '../helpers';
import { hospitalData } from '../opd/PatientCommandCenter';
import { Hospital } from '../opd/models';
import { ActionModal, TActionMode } from './actionModal';
import { DOCTOR_STATUS } from './doctorStatusUpdateModal';
import {
	fetchOPDEscalations,
	IEscalationData,
	IEscalationDataToBeUpdated,
	TEscalationAction,
	TPatientAction,
	updateEscalationsStatus,
} from './fetchEscalations';

export type TDelayRemark = 'SURGERY' | 'AWAY' | 'DELAYED_START' | 'HEAVY_RUSH';

export interface IUpdatedPatientActionData {
	appointment_id: string;
	patient_action_taken: TPatientAction | null;
}

export interface IActionModalSubmitData {
	updatedPatientData: IUpdatedPatientActionData[];
	doctorStatus: TEscalationAction;
	delayRemark: TDelayRemark | null;
	delayedTime: number | null;
}

interface IPillProps {
	children: React.ReactNode;
	className?: string;
}

interface EscalationTableRowProps {
	data: IEscalationData;
	isAcknowledged?: boolean;
	onRowSelect: (data: IEscalationData) => void;
	onOpenActionModal: (params: {
		rowData: IEscalationData;
		mode?: TActionMode;
	}) => void;
}

const Pill: React.FC<IPillProps> = ({ children, className }) => {
	return (
		<div
			className={`bg-opacity-50 border-2 text-sm text-center rounded-full p-0.5 px-2 flex gap-1 items-center justify-center font-semibold shadow-md  border-green-600 bg-green-500 ${className}`}
		>
			{children}
		</div>
	);
};

const EscalationTableRow: React.FC<EscalationTableRowProps> = ({
	data,
	isAcknowledged = false,
	onRowSelect,
	onOpenActionModal,
}) => {
	const escAction = data.escalations.find(
		(esc) => esc.escalation_action !== null
	)?.escalation_action;

	const isPatientActionTaken = data.escalations.some(
		(esc) => esc.patient_action_taken !== null
	);

	// patients against whom action is required; doesn't include yet to arrive patients
	const totalActionablePatients = data.escalations.filter(
		(d) => d.patient_checkedin_time !== null
	);

	const noOfPatientsWithActionTaken = totalActionablePatients.filter(
		(d) => d.patient_action_taken !== null
	)?.length;

	const handleRowClick = () => {
		onRowSelect(data);
		onOpenActionModal({ rowData: data, mode: 'view' });
	};

	const handleActionButtonClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onRowSelect(data);
		onOpenActionModal({ rowData: data, mode: 'add' });
	};

	return (
		<tr
			className={`${
				isAcknowledged
					? 'bg-gray-800'
					: 'text-white text-sm group bg-gray-800 hover:bg-gray-700'
			}`}
			onClick={handleRowClick}
		>
			{/* Doctor Name */}
			<td
				className='group-last:rounded-bl-lg px-6 py-1 whitespace-nowrap text-sm w-[300px] max-w-[300px]'
				colSpan={3}
			>
				{titleCase(data.doctor_name)}
			</td>

			{/* Escalation Level */}
			<td
				colSpan={1}
				className={`px-3 py-2 whitespace-nowrap text-center self-center justify-center text-sm font-semibold`}
			>
				<div
					className={`max-w-[94px] w-[94px] px-3 py-1 text-white rounded-full inline-block bg-opacity-50 border-2 ${
						severityColorPalette[data.escalation_level]
					}`}
				>
					{data.escalation_level !== 'NO_ESCALATION'
						? data.escalation_level
						: 'NE'}
				</div>
			</td>

			{/* Average Wait Time */}
			<td
				colSpan={2}
				className={`px-3 py-2 whitespace-nowrap text-center self-center justify-center text-sm font-semibold`}
			>
				{data.avg_wait_time}
			</td>

			{/* Wait Count */}
			<td className='px-3 py-1 whitespace-nowrap text-center' colSpan={1}>
				{totalActionablePatients.length}
			</td>

			{/* Action Taken */}
			<td
				colSpan={3}
				className='px-6 py-1 whitespace-nowrap self-left w-[300px] max-w-[300px]'
			>
				<div className='flex justify-start gap-2'>
					{escAction === 'DOCTOR_NOT_REACHABLE' ||
					(isPatientActionTaken && escAction !== undefined) ? (
						<>
							<Pill>
								<CheckCircleOutlined />
								{DOCTOR_STATUS.find((d) => d.value === escAction)?.label}
								{data.escalations.find(
									(esc) => esc.patient_action_taken !== null
								)?.escalation_action === 'DOCTOR_DELAY'}
							</Pill>

							{escAction === 'DOCTOR_DELAY' && (
								<Pill className='!px-3'>
									{data.doctor_delay_duration + ' mins'}
								</Pill>
							)}

							{!isAcknowledged && (
								<Pill className='!px-4'>
									{noOfPatientsWithActionTaken +
										' / ' +
										totalActionablePatients.length}
								</Pill>
							)}
						</>
					) : (
						<Button
							variant='contained'
							size='small'
							color='info'
							onClick={handleActionButtonClick}
						>
							Take Action
						</Button>
					)}
				</div>
			</td>

			{/* Edit Action */}
			<td
				className='px-6 py-1 group-last:rounded-br-lg w-[80px] max-w-[80px] cursor-pointer'
				onClick={handleActionButtonClick}
			>
				{escAction !== undefined && <EditIcon className='cursor-pointer' />}
			</td>
		</tr>
	);
};

const EscalationView = () => {
	const navigate = useNavigate();

	const [actionModalState, setActionModalState] = useState<{
		show: boolean;
		mode: TActionMode;
	}>({ show: false, mode: 'add' });

	const [escalationData, setEscalationData] = useState<IEscalationData[]>([]);
	const [latestEscalationTime, setLatestEscalationTime] = useState('');
	const [hideAcknowledgedAppointments, setHideAcknowledgedAppointments] =
		useState(false);
	const [selectedRowData, setSelectedRowData] =
		useState<IEscalationData | null>(null);
	const [selectedHospital, setSelectedHospital] = useState<Hospital | null>(
		null
	);

	const handlePrint = useCallback(() => {
		// TODO: write function to print here
	}, []);

	const handleCSVExport = useCallback(() => {
		// TODO: write function to export CSV file here
	}, []);

	const acknowledgedEscalations = useMemo(
		() => escalationData.filter((e) => e.acknowledged === true),
		[escalationData]
	);

	const escAction = useMemo(() => {
		return (
			selectedRowData?.escalations.find((esc) => esc.escalation_action !== null)
				?.escalation_action ?? null
		);
	}, [selectedRowData]);

	const handleActionButtonSubmit = useCallback(
		(d: IActionModalSubmitData) => {
			const updatedEscalationData: IEscalationDataToBeUpdated = {
				escalation_action: d.doctorStatus,
				doctor_id: String(selectedRowData?.doctor_id),
				doctor_delay_duration:
					d.doctorStatus === 'DOCTOR_DELAY' ? Number(d.delayedTime) : null,
				appointments: d.updatedPatientData,
				doctor_comment:
					d.doctorStatus === 'DOCTOR_DELAY' ? d.delayRemark : null,
			};

			updateEscalationsStatus(updatedEscalationData).then(() => {
				fetchOPDEscalations(
					selectedHospital?.id ?? '9757a411-668d-4261-bf93-bb97132cde6a'
				).then((data) => {
					setEscalationData(
						data.escalations.filter(
							(e) => e.escalation_level !== 'NO_ESCALATION'
						)
					);

					setLatestEscalationTime(
						new Date(data.latest_updated_time).toLocaleTimeString('en-US', {
							timeStyle: 'short',
						})
					);
				});
			});
		},
		[selectedHospital?.id, selectedRowData?.doctor_id]
	);

	const handleBackToDoctorView = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const handleRowSelect = useCallback((d: any) => {
		setSelectedRowData(d);
	}, []);

	const handleOpenActionModal = useCallback(
		({ mode = 'add' }: { mode?: TActionMode }) => {
			setActionModalState({ show: true, mode });
		},
		[]
	);

	const handleCloseActionModal = useCallback(() => {
		setSelectedRowData(null);
		setActionModalState({ show: false, mode: 'add' });
	}, []);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);

		const branchId =
			urlParams.get('branch_id') || '9757a411-668d-4261-bf93-bb97132cde6a';

		const hospital =
			hospitalData.find((h) => h.id === branchId) || hospitalData[0];

		fetchOPDEscalations(
			hospital.id ?? '9757a411-668d-4261-bf93-bb97132cde6a'
		).then((data) => {
			setEscalationData(
				data.escalations.filter((e) => e.escalation_level !== 'NO_ESCALATION')
			);

			setLatestEscalationTime(
				new Date(data.latest_updated_time).toLocaleTimeString('en-US', {
					timeStyle: 'short',
				})
			);
		});
		setSelectedHospital(hospital);
	}, []);

	// Authorization check for Dwarka Branch only
	if (selectedHospital?.id !== '05478906-a974-4f78-84a2-38ce85c39206') {
		return (
			<div className='bg-black text-white min-h-screen p-2 sm:p-4 lg:p-6 flex items-center justify-center text-lg'>
				You are not authorized to access this page
			</div>
		);
	}

	return (
		<div className='bg-black text-white min-h-screen p-2 sm:p-4 lg:p-6'>
			<Header className='bg-gray-900'>
				<Header.Title icon={HospitalIcon}>
					{selectedHospital?.name || 'Select Hospital'}
				</Header.Title>

				<Button
					variant='contained'
					size='small'
					className='!font-bold'
					startIcon={<ArrowBack />}
					onClick={handleBackToDoctorView}
				>
					Back to Doctor View
				</Button>
			</Header>

			<ActionModal
				mode={actionModalState.mode}
				open={actionModalState.show}
				defaultDoctorStatus={escAction ?? null}
				defaultDelayedTime={selectedRowData?.doctor_delay_duration ?? null}
				defaultDoctorRemarks={
					selectedRowData?.escalations.find((e) => e.doctor_comment !== null)
						?.doctor_comment ?? null
				}
				onClose={handleCloseActionModal}
				avgWaitTime={selectedRowData?.avg_wait_time ?? 0}
				doctorName={selectedRowData?.doctor_name ?? ''}
				handleSubmitButton={handleActionButtonSubmit}
				escalatedAppointmentData={selectedRowData}
			/>

			{
				<div className='flex flex-col bg-gray-900 rounded-lg py-4 px-6 shadow-lg'>
					<div className='flex items-center justify-between p-2 mb-2 w-full'>
						<div className='flex items-center gap-10'>
							<div className='flex gap-2'>
								<Person />

								<span>Backlogged Doctors ({escalationData.length}) </span>
							</div>

							<div className='flex gap-2'>
								Escalation Time: {latestEscalationTime}
							</div>
						</div>

						<div className='flex gap-2 items-center'>
							<IconButton
								onClick={handlePrint}
								className='text-white mr-2 !border !border-white rounded-lg'
							>
								<PrintIcon color='primary' />
							</IconButton>

							<IconButton onClick={handleCSVExport} className='text-white'>
								<GridIcon color='primary' />
							</IconButton>
						</div>
					</div>

					<table className='w-full min-w-full !rounded-lg overflow-hidden divide-y'>
						<thead className='bg-[#11141e]'>
							<tr>
								<th
									scope='col'
									colSpan={3}
									className='px-6 py-2.5 text-left text-xs font-bold text-gray-300 uppercase tracking-wider rounded-tl-lg'
								>
									Doctor
								</th>

								<th
									scope='col'
									colSpan={1}
									className='px-6 py-2.5 text-xs text-center font-bold text-gray-300 uppercase tracking-wider rounded-tl-lg'
								>
									Level
								</th>

								<Tooltip
									key='spill'
									title='Wait Time = Current Time - Appt Time'
									arrow
								>
									<th
										scope='col'
										colSpan={2}
										className='px-3 py-2.5 text-center text-xs whitespace-nowrap text-gray-300 uppercase tracking-wider font-bold'
									>
										Avg Post-Appt Wait Time
										<br /> <span className='text-xs'>(in mins)</span>
									</th>
								</Tooltip>

								<th
									scope='col'
									colSpan={1}
									className='px-3 py-2.5 text-center text-xs font-bold text-gray-300 whitespace-nowrap uppercase tracking-wider'
								>
									Wait Count
								</th>

								<th
									colSpan={3}
									scope='col'
									className='px-6 py-2.5 text-xs  text-left text-gray-300 uppercase tracking-wider font-bold rounded-tr-lg'
								>
									Action Taken
								</th>

								<th
									colSpan={1}
									scope='col'
									className='px-6 py-2.5 text-xs  text-left text-gray-300 uppercase tracking-wider font-bold rounded-tr-lg  w-[80px] max-w-[80px]'
								></th>
							</tr>
						</thead>

						<tbody className='bg-gray-700 divide-y box-border'>
							{escalationData
								.filter((esc) => !esc.acknowledged)
								.map((data, i) => {
									return (
										<EscalationTableRow
											data={data}
											isAcknowledged={false}
											onRowSelect={handleRowSelect}
											onOpenActionModal={handleOpenActionModal}
										/>
									);
								})}

							{acknowledgedEscalations.length !== 0 && (
								<tr
									className='bg-[#69dd5e] text-white hover:bg-opacity-75 bg-opacity-65 text-center cursor-pointer'
									onClick={() =>
										setHideAcknowledgedAppointments((prev) => !prev)
									}
								>
									<td
										colSpan={11}
										className='px-6 py-2 whitespace-nowrap text-sm font-semibold items-center align-middle'
									>
										<div className='inline-flex items-center justify-center gap-2'>
											ACKNOWLEDGED
											<CheckCircleOutlineOutlined />
										</div>

										{hideAcknowledgedAppointments ? (
											<ExpandLess className='!float-end' />
										) : (
											<ExpandMore className='!float-end' />
										)}
									</td>
								</tr>
							)}

							{!hideAcknowledgedAppointments &&
								acknowledgedEscalations.map((data, i) => {
									return (
										<EscalationTableRow
											data={data}
											isAcknowledged={true}
											onRowSelect={handleRowSelect}
											onOpenActionModal={handleOpenActionModal}
										/>
									);
								})}
						</tbody>
					</table>
				</div>
			}
		</div>
	);
};

export default EscalationView;
